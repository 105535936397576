import React, {useState} from 'react'
import styles from "./CalendarDay.module.css";
import PropTypes from 'prop-types';
import statusCode from "../../constants/status_codes";
import {Dialog, DialogContent, DialogTitle, Grid, makeStyles, Typography} from "@material-ui/core";
import Controls from "../../controls/Controls";
import CloseIcon from "@material-ui/icons/Close";
/**
 * Calendar day displayed in month range
 */

const CalendarDay = (props) => {


    const [openPopup, setOpenPopup] = useState(false);

    const useStlyles = makeStyles(theme => ({
            dialogWrapper : {
            padding : theme.spacing(2),
            position:'absolute',
            top: theme.spacing(5),
            border: '2px solid #000'
        },
        dialogueTitle:{
            paddingRight:'0px'
        }
    }))

    const  classes =useStlyles();



    const renderSessions = () => {
        let sessions = [];
        let monText;
        for (let i = 0; i < props.sessions.length; i++) {
            if(i === 2) {
                sessions.push(
                    <div key={i} className={styles.session} style={{backgroundColor: '#ffaca7'}}>
                        {props.sessions.length - i + " autre(s)"}
                    </div>
                );
                break;
            }


            else{

                sessions.push(
                    <div key={i} className={styles.session}
                         style={props.sessions[i].TypeIntervention === statusCode.poctuelle ? {backgroundColor: '#33B5FF'} : {backgroundColor: '#FFA233'} }>

                        <div className={styles.monText}>
                            {props.sessions[i].SeanceDebut.slice(10,16)+" à "+props.sessions[i].SeanceFin.slice(10,16)}
                        </div>

                        <button onClick={()=> setOpenPopup(true)}> {props.sessions[i].Evenement}</button>

                        <Dialog open={openPopup} maxWidth="md" classes={{ paper :classes.dialogWrapper}}>
                            <DialogTitle  className={classes.dialogueTitle}>
                                <div style={{display :'flex'}}>
                                    <Typography variant="h6" component="div" color="initial" style={{flexGrow:1}}>
                                        {props.sessions[i].Evenement}

                                    </Typography>
                                    <Controls.ActionButton
                                        color="primary"
                                        onClick={()=>{setOpenPopup(false)}}>
                                        <CloseIcon/>
                                    </Controls.ActionButton>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <Typography>Type de Seance :</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{props.sessions[i].Statut_Libelle}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>Statut de la Seance:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{props.sessions[i].Statut_Libelle}</Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Typography>Espace:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{props.sessions[i].Espace}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>Debut de la Seance:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{props.sessions[i].SeanceDebut}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>Fin de la Seance:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>{props.sessions[i].SeanceFin}</Typography>
                                        </Grid>

                                    </Grid>
                                </div>
                            </DialogContent>

                        </Dialog>
                    </div>
                );
            }
        }

        return sessions;
    };

    return(
        <div className={props.currentMonth === props.date[1] ? styles.container : styles.disabledContainer}>
            <p className={styles.date}><
                b>{props.date[0]}</b></p>

            <div className={styles.sessionsContainer}>
                {renderSessions()}
            </div>
        </div>
    )
};



CalendarDay.propTypes = {
    date: PropTypes.arrayOf(PropTypes.number).isRequired,
    currentMonth: PropTypes.number,
    sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
};



CalendarDay.defaultProps = {
    date: [0,0],
    currentMonth: 0,
    sessions: [],
};

export default CalendarDay;