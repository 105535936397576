import React from 'react';
import PropTypes from 'prop-types';
import styles from './widget.module.css'

const Widget = (props) => {
    return(
        <div
            className={"border-2 border-blue-900 p-3 text-center"}
            style={props.reversed?
                {border:'2px solid '+ props.color, color:props.color, boxShadow : props.shadow + " 1em 1em", backgroundColor:'white'}
                :
                {border:'2px solid '+ props.color, backgroundColor:props.color, boxShadow : props.shadow + " 1em 1em"}
            }
        >
            <img className={"mx-auto h-8 w-8"} src={props.icon} alt={'icon'}/>
            <p className={styles.title}>{props.title}</p>
            <p className={styles.value}>{props.value}</p>
        </div>
    )
};

Widget.propTypes = {
    title:PropTypes.string.isRequired,
    value:PropTypes.any.isRequired,
    icon:PropTypes.string,
    color:PropTypes.string.isRequired,
    shadow:PropTypes.string,
    reversed:PropTypes.bool,
};

Widget.defaultProps = {
    title:'Titre',
    value:'Valeur',
    icon:'',
    color:'#bababa',
    shadow:'transparent',
    reverse:false,
};

export default Widget;