import {createContext, useContext, useState} from 'react';
import * as Proptypes from 'prop-types';

const authContext = createContext();

const useAuthProvider = () => {
  const [user, setUser] = useState({});

  const getHeaders = params => {
    return params || {};
  };

  const get = route => fetch(process.env.REACT_APP_API_URL + route, { headers: getHeaders(), method: 'GET' })
    .then(res => res.json())
    .catch(err => err);

  const post = (route, params, method = 'POST') => fetch(process.env.REACT_APP_API_URL + route, {
    headers: getHeaders({ Accept: 'application/json', 'Content-Type': 'application/json' }),
    method,
    body: JSON.stringify(params),
  }).then(res => res.json()).catch(err => err);

  const put = (route, params) => post(route, params, 'PUT').catch(err => err);

  const del = (route, params) => (
    fetch(process.env.REACT_APP_API_URL + route, { headers: getHeaders(), method: 'DELETE', ...params })
      .then(res => res.status === 204).catch(err => err)
  );

  return {
    user,
    setUser,
    get,
    post,
    put,
    delete: del,
  };
};

const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: Proptypes.node.isRequired,
};

const useAuth = () => {
  return useContext(authContext)
};

export { authContext as default, AuthProvider, useAuth };
