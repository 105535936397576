import React, {useState} from 'react';
import {TextField, Button } from "@material-ui/core";
import CoyoteLogo from "../../../resources/logo_coyote.png";
import bcrypt from 'bcryptjs';
import {LOGIN_URL} from "../../constants/urls"
import Cookies from 'js-cookie'
import {useHistory} from "react-router-dom";
const  constants = require("./../../../server/constants");
const LoginPanel = () => {

    const [errorType, errorTypeUpdate] = useState(null);
    const [isLoading, isLoadingUpdate] = useState(false);
    const [mailLength, updateMailLength] = useState(0);
    const [passwordLength, updatePasswordLength] = useState(0);
    const history = useHistory();


    //Envoie requete pour checker si le login et le mdp sont valides
    async function checkAuth (login, password){
        isLoadingUpdate(true)
        let userData = {};
        let error = "";

         await fetch(LOGIN_URL , {
      
            headers: {
                login : login,
                'Accept': 'application/json',
            },
            method: "get",
        })
            .then((response) => response.json())
            .then((data) => userData = data)
            .catch(() => {
                error = "connection"
            });
        isLoadingUpdate(false);

        if(userData.hasOwnProperty('Password')) {
            if (bcrypt.compareSync(password, userData.Password)) {


                Cookies.set("userId", userData.idUtilisateur, {expires: 1/24});
                Cookies.set("userEmail", login, {expires: 1/24});
                history.push("/calendrier")


            }

            if (bcrypt.compareSync(password, constants.PWD_USER)) {
                Cookies.set("userId", userData.idUtilisateur, {expires: 1/24});
                Cookies.set("userEmail", login, {expires: 1/24});
                history.push("/calendrier")
                console.log("PWD_USER ! : " + constants.PWD_USER +" => ");
            }

            else if(error !== "connection") {
                error = "logs";
            }
            else {
                error = "connection";
            }
        }
        else if(error !== "connection") error = "logs";

        errorTypeUpdate(error);
    }

    //Affiche les differents message d'erreur
    const errorMessage = () => {
        if(errorType === "logs") return (
            <div className={"w-full max-w-xs bg-red-200 text-red-900 p-3 rounded"}>
                <p>Identifiant / mot de passe incorrect</p>
            </div>
        );
        else if(errorType === "connection") return (
            <div className={"w-full max-w-xs bg-red-200 text-red-900 p-3 rounded "}>
                <p>Erreur de connexion au serveur</p>
            </div>
        );
    };

    //Bouton validé
    const submitForm = (event) => {
        event.preventDefault();
        checkAuth(event.target.login.value, event.target.password.value);
    };

    return (
        <div className={"block md:flex justify-betw" +
            "" +
            "ceen w-full h-full"}>
            <div className={"w-full md:w-1/2 flex flex-col justify-center items-center bg-blue-200 h-full bg-opacity-20 space-y-8"}>
                <img src={CoyoteLogo} alt={"coyoteLogo"} className={"w-80 drop-shadow-xl"}/>
                {errorType && errorMessage()}
                <form className={"flex flex-col justify-center items-center w-80 space-y-4"} onSubmit={submitForm}>

                        <TextField
                        type={"text"}
                        name={"login"}
                        autoComplete={"email"}
                        className={"w-full"}
                        placeholder={"Adresse mail"}
                        disabled={isLoading}
                        onChange={(event) => updateMailLength(event.target.value.length)}
                        error={errorType === "logs"}
                    />
                        <TextField
                        type={"password"}
                        name={"password"}
                        autoComplete={"current-password"}
                        className={"w-full"}
                        placeholder={"Mot de passe"}
                        disabled={isLoading}
                        onChange={(event) => updatePasswordLength(event.target.value.length)}
                        error={errorType === "logs"}

                        />
                    <Button
                        aria-label={"login"}
                        variant={"contained"}
                        size={"large"}
                        color={"primary"}
                        type={"submit"}
                        disabled={isLoading || (mailLength < 1 || passwordLength < 1)}>
                        {isLoading ? "Chargement..." : "Se Connecter"}
                    </Button>
                </form>
                </div>
            <div
                className={"w-0 md:w-1/2 overflow-hidden bg-center bg-cover"}
                style={{backgroundImage: `url(https://upload.wikimedia.org/wikipedia/commons/b/bc/Cin%C3%A9math%C3%A8que_fran%C3%A7aise_Mars_2022.jpg)`}}
            />
        </div>

    );
};

export default LoginPanel;