import React from "react";
import "../styles.css";
import {Tab, Tabs, MenuItem, IconButton, Menu, Toolbar, ListItemIcon,} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { constructHeader } from "../util";
import {LOGIN_URL} from "../components/constants/urls";
import Cookies from "js-cookie";


export const AppHeader = ({ tabValue }) => {
  const tabs = ["calendrier","journeeUser","journeeCurrent","indisponibilitesUser"];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event, newValue) => history.push(tabs[newValue]);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };


  const onClickProfile = () => {
        fetch(LOGIN_URL, { headers: constructHeader() }).then((res) => {
            history.push("/profile");
        });
  };

 const onClickLogout = () => {
     Cookies.remove('userId');
    fetch(LOGIN_URL, { headers: constructHeader() }).then((res) => {
      localStorage.clear();
      history.push("/");
    });
  };

  return (
    <div className={"w-full bg-blue-900 mb-5 text-white font-bold"}>
        <Toolbar className={"flex justify-between text-white"}>
          <Tabs value={tabValue} onChange={handleClick}>
            <Tab    label="CALENDRIER" />
            <Tab    label="MA JOURNEE" />
            <Tab    label="JOURNEE A LA CINEMATHEQUE" />
            <Tab    label="GERER LES INDISPONIBILITES " />

          </Tabs>
          <IconButton aria-label=" current user" aria-controls="menu-appbar" aria-haspopup="true"    color="inherit"
            onClick={handleMenu}
          >
            <AccountCircle />
          </IconButton>
          <Menu id="menu-appbar" anchorEl={anchorEl} open={open}  onClose={handleClose}>
              <MenuItem onClick={onClickProfile}>Profil</MenuItem>
              <MenuItem onClick={onClickLogout}>Déconnexion</MenuItem>
          </Menu>
        </Toolbar>
    </div>
  );
};
