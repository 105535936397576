let mobileStyles = {
    container:{
        width:'100%',
        marginTop: '1em',
    },

    agenda:{

    },

    widgetsContainer:{
        marginTop:'1em',
        display:'flex',
        justifyContent: 'space-around',
        alignItems:'space-between',
        overflow: 'hidden',
        transition:'all .5s',
        flexWrap:'wrap'
    }
};

export default mobileStyles;