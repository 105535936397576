import React, {useState, useEffect} from 'react';
import styles from './Planning.module.css';
import Session from "../Session/Session";
import {JOURNEECUSER_URL} from '../../../constants/urls';
import Cookies from "js-cookie";
import statusCode from "../../constants/status_codes";

/**
 * Planning set on Dashboard
 */

const Planning = (props) => {

    const [isExtended, isExtendedUpdate] = useState(false);
    let [mySessions, mySessionsUpdate] = useState([]);
    let [people, peopleUpdate] = useState([]);
    const [allSessions, allSessionsUpdate] = useState([]);
    const sessionSize = 4;


    const parseSessions = (data) => {
        let tempMySessions = [];
        let tempPeople = [];
        let tempAllSessions = [];

        let interventions = 0;
        let interventionTime = 0;
        let money = 0;

        for(let i = 0; i< data.length; i++){
            if(data[i].IdUtilisateur === props.userId) {

                tempMySessions.push(data[i]);
                interventions++;
                if(data[i].SeanceDebut !== "") {
                    interventionTime += (hmsToHours(data[i].SeanceFin.split(' ')[1]) - hmsToHours(data[i].SeanceDebut.split(' ')[1]));
                    money += data[i].RemunerationHoraire * (hmsToHours(data[i].SeanceFin.split(' ')[1]) - hmsToHours(data[i].SeanceDebut.split(' ')[1]));
                }
                else{
                    interventionTime += (hmsToHours(data[i].InterventionFin.split(' ')[1]) - hmsToHours(data[i].InterventionDebut.split(' ')[1]));
                    money += data[i].RemunerationHoraire * (hmsToHours(data[i].InterventionFin.split(' ')[1]) - hmsToHours(data[i].InterventionDebut.split(' ')[1]));
                }
            }
            else {
                if(tempPeople.indexOf(data[i].PrenomCivil + " " + data[i].NomCivil) !== -1){
                    tempAllSessions[tempPeople.indexOf(data[i].PrenomCivil + " " + data[i].NomCivil)].push(data[i]);
                }
                else{
                    tempPeople.push(data[i].PrenomCivil + " " + data[i].NomCivil);
                    tempAllSessions.push([]);
                    tempAllSessions[tempAllSessions.length - 1].push(data[i]);
                }
            }
        }
        props.widgetsCallback(interventions, interventionTime, money);
        mySessionsUpdate(tempMySessions);
        peopleUpdate(tempPeople);
        allSessionsUpdate(tempAllSessions);
    };

    useEffect(() => {
        let today = new Date();
        fetch(JOURNEECUSER_URL,
            {
                headers: {
                    'Accept': 'application/json',
                    id : props.userId,
                    auth: Cookies.get("auth"),

                    today: (today.getMonth()+1).toLocaleString(undefined, {minimumIntegerDigits: 2} )+
                        today.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2})+
                        today.getFullYear(),

                },
                method: 'GET',
            })
            .then(response => response.json())
            .then(response => parseSessions(response.Data))
            .catch(error => console.log(error));
        //eslint-disable-next-line
    },[]);
 console.log("La valeur de bouba ",props.userId);
    //Permet de remplir l'agenda entre les sessions
    const fillAgenda = (lastSession, newSession) => {
        if (newSession - lastSession > 0) {
            return (<div style={{height: (newSession - lastSession) * sessionSize + 'em', backgroundColor: '#c4c4c4'}}/>);
        }
    };

    //Transforme le fort HH:MM:SS en H,h
    const hmsToHours = (hms) => {
        let hours = parseInt(hms?.split(':')[0],10);
        hours += parseInt(hms?.split(':')[1], 10)/60;
        return hours
    };


    //Rendu des sessions dans l'agenda
   
    const renderSessions = () => {

            let lastSession = 8;
            return(
                <div className={styles.planingUserContainer} >
                    {allSessions.map((peopleSession,index)=> {
                        lastSession = 8;
                        return(
                            <div key={index} className={styles.planning}>

                                <div className={styles.name}>
                                    {people[index]}
                                </div>
                                {peopleSession.map((session, key) =>
                                    <div key={key}>
                                        {session.SeanceDebut !== '' ?
                                            fillAgenda(lastSession, hmsToHours(session.SeanceDebut.split(' ')[1]))
                                            :
                                            fillAgenda(lastSession, hmsToHours(session.InterventionDebut.split(' ')[1]))
                                        }
                                        <div className="text-blue-900">
                                            <Session
                                                title={session.Evenement === "" ? session.Programme : session.Evenement}
                                                place={session.Espace}
                                                startingTime={session.SeanceDebut !== '' ? session.SeanceDebut.split(' ')[1] : session.InterventionDebut.split(' ')[1]}
                                                endingTime={session.SeanceFin !== '' ? session.SeanceFin.split(' ')[1] : session.InterventionFin.split(' ')[1]}
                                                details={session.DETAILS}
                                                job={session.Metier}
                                                rgb={session.Code_Statut === statusCode.valid ? colorHash(session.Evenement).rgb : colorHash(session.Evenement).rgb}
                                                prescripteur={session.Prescripteur}
                                            />
                                        </div>
                                        <div style={{display: 'none'}}>
                                            {lastSession = (session.SeanceFin !== '' ? hmsToHours(session.SeanceFin.split(' ')[1]) : hmsToHours(session.InterventionFin.split(' ')[1]))}
                                        </div>

                                    </div>
                                )}
                            </div>)
                            }
                        )}
                </div>
            )

    };


    const colorHash = (inputString) => {
        let sum = 0;
        for(let i in inputString){
            sum += inputString.charCodeAt(i);
        }

        let r = ~~(('0.'+Math.sin(sum+1).toString().substr(6))*256);
        let g = ~~(('0.'+Math.sin(sum+2).toString().substr(6))*256);
        let b = ~~(('0.'+Math.sin(sum+3).toString().substr(6))*256);

        let rgb = "rgb("+r+", "+g+", "+b+")";

        let hex = "#";

        hex += ("00" + r.toString(16)).substr(-2,2).toUpperCase();
        hex += ("00" + g.toString(16)).substr(-2,2).toUpperCase();
        hex += ("00" + b.toString(16)).substr(-2,2).toUpperCase();

        return {
            r: r
            ,g: g
            ,b: b
            ,rgb: rgb
            ,hex: hex
        };
    };

    return(
        //Header
        <div className={isExtended ? styles.containerExtended : styles.container}>

            {/*Agenda*/}
            <div className={isExtended ? styles.planningContainerExtended: styles.planningContainer}>
                <div className={"flex flex-col justify-between pt-16 text-blue-900"}>
                    <div>
                        8h
                    </div>
                    <div>
                        10h
                    </div>
                    <div>
                        12h
                    </div>
                    <div>
                        14h
                    </div>
                    <div>
                        16h
                    </div>
                    <div>
                        18h
                    </div>

                </div>


                {renderSessions()}
            </div>


        </div>
    )
};


export default Planning;