import React, { useState } from "react";
import {Grid, FormControlLabel, Checkbox, Button, Paper, makeStyles} from "@material-ui/core";
import {DISPONIBILITY_URL_BB, EFFACE_URL_BB} from "../../../constants/urls";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment/moment";
import {addDays} from "date-fns";
import Notification from "../../Notification";
import ConfirmDialog from "../../ConfirmDialog";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import {useAuth} from "../../../hooks/Auth";
registerLocale('fr', fr)


export const Disponibilite =  ()  => {

    const auth = useAuth();

    const  useStyles = makeStyles((theme) => ({
        root : {
            flexGrow :1
        },

        gridItem :{
            textAlign :'center',
            width:'12%',
            margin:theme.spacing(2)
        },

        gridItemCrenAM :{
            textAlign :'center',
             width:'5%',

        },
        gridItemCrenPM :{
            textAlign :'center',
            width:'7%',
            margin:theme.spacing(2)

        },
        paper :{
            padding:theme.spacing(2),
            textAlign :'center',
            color :theme.palette.text.primary,
            border:"3px solid  #33AAFF",
            borderRadius :25,

        }
    }));

    /*********** useState debutIndispo  finIndispo  ************************/
   const classes = useStyles();
    const [ debutIndispo, setDebutIndispo ] = useState();
    const [ finIndispo, setFinIndispo ] = useState();

    /************ useState Lundi ************************/
    const [ myCheckedlm, setMyCheckedlm ]= useState(false);
    const [ myCheckedlam, setMyCheckedlam ]= useState(false);

    /************ useState Mardi ************************/
    const [ myCheckedMam, setMyCheckedMam ]= useState(false);
    const [ myCheckedlMaam, setMyCheckedMaam ]= useState(false);

    /************ useState Mercredi ************************/
    const [ myCheckedMem, setMyCheckedMem ]= useState(false);
    const [ myCheckedMeam, setMyCheckedMeam ]= useState(false);


    /************ useState Jeudi ************************/
    const [ myCheckedJeum, setMyCheckedJeum ]= useState(false);
    const [ myCheckedJeuam, setMyCheckedJeuam ]= useState(false);

    /************ useState Venderdi ************************/
    const [ myCheckedVendrem, setMyCheckedVendrem ]= useState(false);
    const [ myCheckedVendream, setMyCheckedVendream ]= useState(false);

    /************ useState Samedi ************************/
    const [ myCheckedSamedim, setMyCheckedSamedim]= useState(false);
    const [ myCheckedSamediam, setMyCheckedSamediam ]= useState(false);

    /************ useState Dimanche ************************/
    const [ myCheckedDimanchem, setMyCheckedDimanchem]= useState(false);
    const [ myCheckedDimancheam, setMyCheckedDimancheam ]= useState(false);

    /*    *********** useState nbrSemaineR ************************ */

    const [open, setOpen] = useState(false);


    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [ confirmDialog, setConfirmDialog ] = useState({isOpen: false, title:'',subTitle:''});
    /************ useState Dimanche ************************/
    const [ matin, setMatin]= useState(false);
    const [ apresmidi, setApresmidi ]= useState(false);


    /************Event Lundi ************************/
    const onChangeLundiMatin = (e) => {
        setMyCheckedlm(e.target.checked);

    } ;

    const onChangeLundiAPMidi = (e) => {
        setMyCheckedlam(e.target.checked);
    };


    /************Event Mardi ************************/

      const onChangeMardiMatin = (e) => {
          setMyCheckedMam(e.target.checked);
      } ;

      const onChangeMardiAPMidi = (e) => {
          setMyCheckedMaam(e.target.checked);
      };


    /************Event Mercredi ************************/
   const onChangeMercrediMatin = (e) => {
        setMyCheckedMem(e.target.checked);

    } ;

    const onChangeMercrediAPMidi = (e) => {
        setMyCheckedMeam(e.target.checked);

    };

    /*************Event Jeudi************************/
    const onChangeJeudiMatin = (e) => {
        setMyCheckedJeum(e.target.checked);
    } ;

    const onChangeJeudiAPMidi = (e) => {
        setMyCheckedJeuam(e.target.checked);
    };

    /************Event Vendredi ************************/

    const onChangeVendrediMatin = (e) => {
        setMyCheckedVendrem(e.target.checked);
    } ;

    const onChangeVendrediAPMidi = (e) => {
        setMyCheckedVendream(e.target.checked);
    };

    /************Event Samedi ************************/
    const onChangeSamediMatin = (e) => {
        setMyCheckedSamedim(e.target.checked);
    } ;

    const onChangeSamediAPMidi = (e) => {
        setMyCheckedSamediam(e.target.checked);

    };

    /************Event Dimanche ************************/

    const onChangeDimancheMatin = (e) => {
        setMyCheckedDimanchem(e.target.checked);
    } ;

    const onChangeDimancheAPMidi = (e) => {
        setMyCheckedDimancheam(e.target.checked);

    };


    /*    ***********onChangeDebutIndispo onChangeFinIndispo  ************************/
    const onChangeDebutIndispo = (debutIndispo) => {

        setDebutIndispo(debutIndispo);
    };

    const onChangeFinIndispo = (finIndispo) => {
        if(debutIndispo){
            setFinIndispo(finIndispo);
        }
        if(!debutIndispo && !finIndispo){
            setFinIndispo(finIndispo);
        }
        if(debutIndispo && !finIndispo){
            setFinIndispo(finIndispo);
        }
        if(debutIndispo && finIndispo){
            setFinIndispo(finIndispo);
        }
        else{
            setFinIndispo(null);
        }


    };
    const redirect = () => {
        localStorage.clear();

    };
    const clearTextFields = () => {
        setDebutIndispo("");
        setFinIndispo("");
    };



    /************  Data Lundi ************************/


    const datalm = {
        userId: auth.user.id,
        start: ((myCheckedlm && moment(debutIndispo).day() <= 1) ? moment(debutIndispo).day('1').format('MMDDYYYY')
            || (myCheckedlm && moment(debutIndispo).day() > 1) : moment(debutIndispo).day('8').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"AM",
        actionIntervention:'C'
    };
    const datalam = {
        userId: auth.user.id,
        start: ((myCheckedlam && moment(debutIndispo).day() <= 1) ? moment(debutIndispo).day('1').format('MMDDYYYY')
               || (myCheckedlam && moment(debutIndispo).day() > 1) : moment(debutIndispo).day('8').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"PM",
        actionIntervention:'C'
    };

    /************  Data Mardi ************************/

        const dataMam = {
        userId: auth.user.id,
        start: ((myCheckedMam && moment(debutIndispo).day() <= 2) ? moment(debutIndispo).day('2').format('MMDDYYYY')
            || (myCheckedMam && moment(debutIndispo).day() > 2) : moment(debutIndispo).day('9').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"AM",
        actionIntervention:'C'
    };
    const dataMaam = {
        userId: auth.user.id,
        start: ((myCheckedlMaam && moment(debutIndispo).day() <= 2) ? moment(debutIndispo).day('2').format('MMDDYYYY')
            || (myCheckedlMaam && moment(debutIndispo).day() > 2) : moment(debutIndispo).day('9').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"PM",
        actionIntervention:'C'
    };


    /************  Data Mercredi  ************************/

    const dataMerm = {
        userId: auth.user.id,
        start: ((myCheckedMem && moment(debutIndispo).day() <= 3) ? moment(debutIndispo).day('3').format('MMDDYYYY')
               || (myCheckedMem && moment(debutIndispo).day() > 3) : moment(debutIndispo).day('10').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"AM",
        actionIntervention:'C'
        };

    const dataMeram = {
        userId: auth.user.id,
        start: ((myCheckedMeam && moment(debutIndispo).day() <= 3) ? moment(debutIndispo).day('3').format('MMDDYYYY')
              || (myCheckedMeam && moment(debutIndispo).day() > 3) : moment(debutIndispo).day('10').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"PM",
        actionIntervention:'C'
    };

    /************  Data Jeudi ************************/
    const dataJeum = {
        userId: auth.user.id,
        start: ((myCheckedJeum && moment(debutIndispo).day() <=4) ? moment(debutIndispo).day('4').format('MMDDYYYY')
               || (myCheckedJeum && moment(debutIndispo).day() >4) : moment(debutIndispo).day('11').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"AM",
        actionIntervention:'C'
    };

    const dataJeuam = {
        userId: auth.user.id,
        start: ((myCheckedJeuam && moment(debutIndispo).day() <= 4)  ? moment(debutIndispo).day('4').format('MMDDYYYY')
              || (myCheckedJeuam && moment(debutIndispo).day() > 4) : moment(debutIndispo).day('11').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"PM",
        actionIntervention:'C'
    };
    /************  Data Vendredi ************************/
    const dataVendrem = {
        userId: auth.user.id,
        start: ((myCheckedVendrem && moment(debutIndispo).day() <= 5 ) ? moment(debutIndispo).day('5').format('MMDDYYYY')
               ||(myCheckedVendrem && moment(debutIndispo).day() > 5) : moment(debutIndispo).day('12').format('MMDDYYYY')),
        end:moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"AM",
        actionIntervention:'C'
    };

    const dataVendream = {
        userId: auth.user.id,
        start: ((myCheckedVendream && moment(debutIndispo).day() <=5) ? moment(debutIndispo).day('5').format('MMDDYYYY')
               ||(myCheckedVendream && moment(debutIndispo).day() > 5) : moment(debutIndispo).day('12').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"PM",
        actionIntervention:'C'
    };

    /************  Data Samedi ************************/

    const dataSamedimm = {
        userId: auth.user.id,
        start: ((myCheckedSamedim && moment(debutIndispo).day() <=6)  ? moment(debutIndispo).day('6').format('MMDDYYYY')
               || (myCheckedSamedim && moment(debutIndispo).day() > 6) : moment(debutIndispo).day('13').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"AM",
        actionIntervention:'C'
    };

    const dataSamediam = {
        userId: auth.user.id,
        start: ((myCheckedSamediam && moment(debutIndispo).day() <=6) ? moment(debutIndispo).day('6').format('MMDDYYYY')
              || (myCheckedSamediam && moment(debutIndispo).day() > 6) : moment(debutIndispo).day('13').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"PM",
        actionIntervention:'C'
    };
    /***********  Data Samedi ************************/
    const dataDimanchem = {
        userId: auth.user.id,
        start: ((myCheckedDimanchem && moment(debutIndispo).day() === 0) ? moment(debutIndispo).day('0').format('MMDDYYYY')
            || (myCheckedDimanchem && moment(debutIndispo).day() >0) : moment(debutIndispo).day('7').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"AM",
        actionIntervention:'C'
    };

    const dataDimacheam = {
        userId: auth.user.id,
        start: ((myCheckedDimancheam && moment(debutIndispo).day() !== 0) ? moment(debutIndispo).day('7').format('MMDDYYYY')
              || (myCheckedDimancheam && moment(debutIndispo).day() ===0) : moment(debutIndispo).day('0').format('MMDDYYYY')),
        end:  moment(finIndispo).format('MMDDYYYY'),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0),
        period:"PM",
        actionIntervention:'C'
    };
    /************  Data EFFACE ************************/
    const dataEfface = {
        userId: auth.user.id,
        start: moment(debutIndispo).format('MMDDYYYY'),
        end: moment(finIndispo).add(1,'days').format('MMDDYYYY'),
        week: moment(finIndispo).diff(debutIndispo,"weeks"),
        recurrente: (moment(finIndispo).diff(debutIndispo,"weeks")+1 > 2 ? 1 : 0)
    };



    const dataWeek = {
        userId: auth.user.id,
        week:moment(finIndispo).diff(debutIndispo,"weeks")+1,
        actionIntervention:'C'
    };
    /***********     onClick    ************************/
    const onClickAjouter = () =>


         {setConfirmDialog({...confirmDialog, isOpen: false})

            /*    ***********  Data Lundi ************************/



                if(myCheckedlm){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(datalm)
                    })
                        .then((res) => {
                            console.log(res)
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {console.log(res) })
                }

                if(myCheckedlam){
                fetch(DISPONIBILITY_URL_BB, {
                    method:'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify(datalam)
                })
                    .then((res) => {
                        console.log(res)
                        if (res.status === 401)
                            redirect();
                        else {
                            setOpen(true);
                            if (res.status === 201)
                                clearTextFields();
                        }
                    })
                    .catch((res) => {
                        console.log(res) })
                }


             /*    ***********  Data Mardi ************************/

                      if(myCheckedMam){
                          fetch(DISPONIBILITY_URL_BB, {
                              method:'POST',
                              headers: {
                                  "Content-Type": "application/json",
                                  "Accept": "application/json"
                              },
                              body: JSON.stringify(dataMam)
                          })
                              .then((res) => {
                                  console.log(res)
                                  if (res.status === 401)
                                      redirect();
                                  else {
                                      setOpen(true);
                                      if (res.status === 201)
                                          clearTextFields();
                                  }
                              })
                              .catch((res) => {
                                  console.log(res) })
                      }


                      if(myCheckedlMaam){
                          fetch(DISPONIBILITY_URL_BB, {
                              method:'POST',
                              headers: {
                                  "Content-Type": "application/json",
                                  "Accept": "application/json"
                              },
                              body: JSON.stringify(dataMaam)
                          })
                              .then((res) => {
                                  console.log(res)
                                  if (res.status === 401)
                                      redirect();
                                  else {
                                      setOpen(true);
                                      if (res.status === 201)
                                          clearTextFields();
                                  }
                              })
                              .catch((res) => {
                                  console.log(res) })
                      }

             /************ ******************** Data Mercredi  ************************* ************************/
                if(myCheckedMem){

                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(dataMerm)
                    })
                        .then((res) => {
                            console.log(res)
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }
                if(myCheckedMeam){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(dataMeram)
                    })
                        .then((res) => {
                            console.log(res)
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }

        /******************************** Data Jeudi  ************************* ************************/

                if(myCheckedJeum){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(dataJeum)
                    })
                        .then((res) => {
                            console.log(res)
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }
                if(myCheckedJeuam){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(dataJeuam)
                    })
                        .then((res) => {
                            console.log(res)
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }
        /******************************** Data Vendredi  ************************* ************************/
                if(myCheckedVendrem){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(dataVendrem)
                    })
                        .then((res) => {
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }

                if(myCheckedVendream){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(dataVendream)
                    })
                        .then((res) => {
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }
        /******************************** Data Samedi  *************************************************/
                if(myCheckedSamedim){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(dataSamedimm)
                    })
                        .then((res) => {
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }
                if(myCheckedSamediam){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },

                        body: JSON.stringify(dataSamediam)
                    })
                        .then((res) => {
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }
        /******************************** Data Dimanche  ************************* ************************/

                if(myCheckedDimanchem){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(dataDimanchem)
                    })
                        .then((res) => {
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }

                if(myCheckedDimancheam){
                    fetch(DISPONIBILITY_URL_BB, {
                        method:'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        },
                        body: JSON.stringify(dataDimacheam)
                    })
                        .then((res) => {
                            if (res.status === 401)
                                redirect();
                            else {
                                setOpen(true);
                                if (res.status === 201)
                                    clearTextFields();
                            }
                        })
                        .catch((res) => {
                            console.log(res) })
                }
            setNotify({
                isOpen: true,
                message: 'Indisponibilité(s) ajoutée(s) avec succés',
                type: 'success'
            })

    };

        return (
        <div className={"text-center sm: grid-cols-2 md:grid-cols-2 text-black text-sm    "}>

            <Grid container  alignItems="center"  item xs={12} spacing={3}>

                <Grid item xs={12}>
                </Grid>
                <Grid item  className={classes.gridItem}>
                   <Paper className={classes.paper} square variant='outlined'>LUNDI</Paper>
                </Grid>
              <Grid item className={classes.gridItem}>
                    <Paper className={classes.paper} square variant='outlined'>MARDI</Paper>
                </Grid>
              <Grid item className={classes.gridItem}>
                    <Paper className={classes.paper} square variant='outlined'>MERCREDI</Paper>
                </Grid>

                <Grid item className={classes.gridItem}>
                    <Paper className={classes.paper} square variant='outlined'>JEUDI </Paper>
                </Grid>
                <Grid item className={classes.gridItem}>
                    <Paper className={classes.paper} square variant='outlined'>VENDREDI</Paper>
                </Grid>
                <Grid item className={classes.gridItem}>
                    <Paper className={classes.paper} square variant='outlined'>SAMEDI</Paper>
                </Grid>
                <Grid item className={classes.gridItem}>
                    <Paper className={classes.paper} square variant='outlined'>DIMANCHE</Paper>
                </Grid>

                <Grid item xs={12}>
                </Grid>
                <Grid item  className={classes.gridItemCrenAM} >
                    <FormControlLabel
                        control={ <Checkbox
                            value="1"
                            onChange={ onChangeLundiMatin}
                        />}
                        label="Matin" />
                </Grid>

                <Grid item className={classes.gridItemCrenPM}  >
                    <FormControlLabel
                        control={ <Checkbox
                            value="1"
                            onChange={ onChangeLundiAPMidi}
                        />}
                        label="Aprés Midi" />
                </Grid>

                <Grid item   className={classes.gridItemCrenAM} >
                    <FormControlLabel
                        control={ <Checkbox
                            value="2"
                            onChange={ onChangeMardiMatin}
                        />}
                        label="  Matin" />
                </Grid>

                <Grid item  className={classes.gridItemCrenPM}>
                    <FormControlLabel
                        control={ <Checkbox
                            value="2"
                            onChange={ onChangeMardiAPMidi}
                        />}
                        label=" Aprés Midi" />
                </Grid>

           <Grid item  className={classes.gridItemCrenAM}  >
                    <FormControlLabel
                        control={ <Checkbox
                            value="3"
                            onChange={ onChangeMercrediMatin }
                        />}
                        label="Matin" />
                </Grid>

                <Grid item  className={classes.gridItemCrenPM}  >
                    <FormControlLabel
                        control={ <Checkbox
                            value="3"
                            onChange={ onChangeMercrediAPMidi}
                        />}
                        label="Aprés Midi" />
                </Grid>


                <Grid item   className={classes.gridItemCrenAM} >
                    <FormControlLabel
                        control={ <Checkbox disabled={matin || apresmidi }
                            value="4"
                            onChange={ onChangeJeudiMatin }
                        />}
                        label="Matin" />
                </Grid>

                <Grid item >
                    <FormControlLabel
                        control={ <Checkbox disabled={matin || apresmidi }
                            value="4"
                            onChange={ onChangeJeudiAPMidi }
                        />}
                        label="Aprés Midi" />
                </Grid>
                <Grid item className={classes.gridItemCrenAM} >
                    <FormControlLabel
                        control={ <Checkbox disabled={matin || apresmidi }
                            value="5"
                            onChange={ onChangeVendrediMatin }
                        />}
                        label="Matin" />
                </Grid>

                <Grid item className={classes.gridItemCrenPM} >
                    <FormControlLabel
                        control={ <Checkbox disabled={matin || apresmidi }
                            value="5"
                            onChange={ onChangeVendrediAPMidi }
                        />}
                        label="Aprés Midi" />
                </Grid>
                <Grid item  className={classes.gridItemCrenAM}  >
                    <FormControlLabel
                        control={ <Checkbox disabled={matin || apresmidi }
                            value="6"
                            onChange={ onChangeSamediMatin }
                        />}
                        label="Matin" />
                </Grid>

                <Grid item xs={2} sm={1} className={classes.gridItemCrenPM}  >
                    <FormControlLabel
                        control={ <Checkbox
                            value="6" disabled={matin || apresmidi }
                            onChange={ onChangeSamediAPMidi }
                        />}
                        label="Aprés Midi" />
                </Grid>


                <Grid item className={classes.gridItemCrenAM} >
                    <FormControlLabel
                        control={ <Checkbox disabled={matin || apresmidi }
                            value="7"
                            onChange={ onChangeDimancheMatin }
                        />}
                        label="Matin" />
                </Grid>

                <Grid item   className={classes.gridItemCrenPM}  >
                    <FormControlLabel
                        control={ <Checkbox disabled={matin || apresmidi }
                            value="7"
                            onChange={ onChangeDimancheAPMidi }
                        />}
                        label="Aprés Midi" />
                </Grid>

                <Grid item  style={{ marginBottom: "10vh" ,border : "3px solid #76baff", marginLeft:"35%"}}  >
                    <DatePicker
                        placeholderText="Selectionner le debut"
                        selected={debutIndispo}
                        onChange={onChangeDebutIndispo}
                        selectsStart
                        isClearable
                        dateFormat="dd/MM/yyyy"
                        minDate={addDays(new Date(),22) }
                        required
                        locale="fr"
                    />
                </Grid>


                <Grid item  style={{ marginBottom: "10vh"  ,border : "3px solid #76baff", marginLeft:"10%"}}  >
                    <DatePicker
                        placeholderText="Selectionner la fin"
                        selected={finIndispo}
                        onChange={onChangeFinIndispo}
                        selectsEnd
                        isClearable
                        dateFormat="dd/MM/yyyy"
                        minDate={addDays(debutIndispo,15) }
                        required
                        locale="fr"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
            <Grid item style={{ marginBottom: "10vh" }}   >

                    <Button aria-label="login" variant="contained" size="large" color="primary"
                            onClick={() => {

                                fetch(EFFACE_URL_BB, {
                                    method:'POST',
                                    headers: {
                                        "Content-Type": "application/json",
                                        "Accept": "application/json"
                                    },
                                    body: JSON.stringify(dataEfface)
                                })
                                    .then((res) => {
                                        console.log(res)
                                        if (res.status === 401)
                                            redirect();
                                        else {
                                            setOpen(true);
                                            if (res.status === 201)
                                                clearTextFields();
                                        }
                                    })
                                    .catch((res) => {console.log(res) })

                                setConfirmDialog({
                                    isOpen: true,
                                    title: 'Etes vous sùr de vouloir ajouter ces indisponibilités ?',
                                    subTitle: "",
                                    onConfirm : () => { onClickAjouter()}
                                })
                            }}

                           disabled={!(debutIndispo && finIndispo)}

                    >
                    AJOUTER
                    </Button>

            </Grid>
             <Notification
                    notify ={notify}
                    setNotify={ setNotify }
                />

                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                />

            </Grid>

        </div>

    );

}

