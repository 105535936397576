import React, {useState} from "react";
import {AppHeader} from "../../AppHeader";
import {Grid, Paper, Typography} from "@material-ui/core";
import styles from "./Dashboard.module.css";
import Planning from "../../Agenda/Planning/Planning";
import Footer from "../../Footer";



export const JourneeCurrent = () => {

    const [interventions, interventionsUpdate] = useState(0);
    const [interventionTime, interventionTimeUpdate] = useState([0,0]);


    const updateWidgets = (newInterventions, newInterventionTime, newMoney) => {
        interventionsUpdate(newInterventions);
        interventionTimeUpdate([(newInterventionTime/1)>>0,Math.round((newInterventionTime%1) * 60)]);

    };
    return (
        <div>
            <div className="Content">
                <AppHeader tabValue={2} />
              <Grid container justify="center" alignItems="center" direction="column">
                  <Grid item style={{ marginBottom: "5vh" }}>
                      <Typography variant="h4"   color="textSecondary" component="h2" gutterBottom>
                         <Paper>La journée à la Cinémathèque française du {new Date().toLocaleDateString()}</Paper>
                          <span role="img" aria-label="journeeCurrent"></span>
                      </Typography>

                        <div className={styles.dashboardContainer}>
                            <Planning
                                widgetsCallback={updateWidgets}
                            />
                        </div>
                </Grid>
              </Grid>

            </div>
            <Footer/>
        </div>

    );

}