/**
 * Composant chargé du changement de mot de passe
 */


import React, {useState} from 'react';
import styles from './PasswordChanger.module.css';
import bcrypt from 'bcryptjs';
import lock_icon from '../../../resources/icons/lock.png';
import {PASSWORDCHANGE_URL} from "../../../constants/urls";
import Cookies from "js-cookie";
import {TextField} from "@material-ui/core";
import {useAuth} from "../../../hooks/Auth";


const PasswordChanger = (props) => {

    const [edition, editionUpdate] = useState(false);
    const [newPassword, newPasswordUpdate] = useState('');
    const [hasLowerCase, hasLowerCaseUpdate] = useState(false);
    const [hasUpperCase, hasUpperCaseUpdate] = useState(false);
    const [hasSpecial, hasSpecialUpdate] = useState(false);
    const [hasNumber, hasNumberUpdate] = useState(false);
    const [isLongEnough, isLongEnoughUpdate] = useState(false);
    const [isIdentical, isIdenticalUpdate] = useState(false);
    const [passwordChanged, passwordChangedUpdate] = useState(false);
    const [errorHappened, errorHappenedUpdate] = useState(false);
    const auth = useAuth();

    const handleEdit = () => {

        if(edition) {
            fetch(PASSWORDCHANGE_URL, {
                method: "POST",
                headers:{
                    'Content-Type' : 'application/json',
                     auth: Cookies.get("auth")
                },
                body: JSON.stringify({
                    id: auth.user.id,
                    Password: bcrypt.hashSync(newPassword,10),
                })
            })
                .then(response => {
                    if(response.status === 200) {
                        passwordChangedUpdate(true);
                        handleCancel();
                    }
                    else errorHappenedUpdate(true);
                })
                .catch(err=>console.log(err));
        }
        else {
            editionUpdate(true);
        }
    };

    const handleCancel = () => {
        newPasswordUpdate('');
        isIdenticalUpdate(false);
        editionUpdate(false);
        hasSpecialUpdate(false);
        hasLowerCaseUpdate(false);
        hasUpperCaseUpdate(false);
        hasSpecialUpdate(false);
        hasNumberUpdate(false);
        isLongEnoughUpdate(false);
        errorHappenedUpdate(false);
    };

    const handlePasswordChange = (event) =>  {
        const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        const numbers = /\d/;
        hasLowerCaseUpdate(event.target.value.toUpperCase() !== event.target.value);
        hasUpperCaseUpdate(event.target.value.toLowerCase() !== event.target.value);
        hasNumberUpdate(numbers.test(event.target.value));
        hasSpecialUpdate(specialCharacters.test(event.target.value));
        isLongEnoughUpdate(event.target.value.length > 9);
        if(isIdentical) isIdenticalUpdate(false);
        newPasswordUpdate(event.target.value);
    };

    const handleConfirmationChange = (event) => {
        if((event.target.value === newPassword) && newPassword !== '') {
            isIdenticalUpdate(true);
        }
        else {
            isIdenticalUpdate(false);
        }
    };

    return(
        edition === true ?
            <div className={styles.container}>
                {isLongEnough ? null : <p className={styles.warning}> - Le mot de passe doit faire au moins 10 caractères</p>}
                {hasSpecial ? null : <p className={styles.warning}> - Le mot de passe doit contenir au moins un caractère spécial</p> }
                {hasLowerCase ? null : <p className={styles.warning}> - Le mot de passe doit contenir au moins un caractère en minuscule</p>}
                {hasUpperCase ? null : <p className={styles.warning}> - Le mot de passe doit contenir au moins un caractère en majuscule</p>}
                {hasNumber ? null : <p className={styles.warning}> - Le mot de passe doit contenir au moins un chiffre</p>}
                {errorHappened ? <p className={styles.warning}> Une erreur a eu lieu : mot de passe non modifié </p> : null}

                <TextField
                    variant="outlined"
                    color="secondary"
                    required
                    label="Entrez votre nouveau mot de passe"

                    type={"password"}
                    name={"password"}
                    autoComplete={"new-password"}
                    className={(hasLowerCase && hasUpperCase && hasSpecial && hasNumber && isLongEnough) ? styles.validField : styles.invalidField}

                    onChange={handlePasswordChange}
                />
                {isIdentical ? null : <p className={styles.warning}> - La confirmation doit être identique au mot de passe</p>}

                <TextField
                    variant="outlined"
                    color="secondary"
                    required
                    label="Confirmez votre mot de passe"

                    type={"password"}
                    name={"confirmation"}
                    autoComplete={"new-password"}
                    className={isIdentical ? styles.validField : styles.invalidField}

                    onChange={handleConfirmationChange}
                />
                <div>
                    <div onClick={(hasLowerCase && hasUpperCase && hasSpecial && hasNumber && isLongEnough && isIdentical) ? handleEdit  : null}
                         className={(hasLowerCase && hasUpperCase && hasSpecial && hasNumber && isLongEnough && isIdentical) ? styles.editButton : styles.editButtonDisabled}>
                        <b>Valider</b>
                    </div>
                    <div onClick={handleCancel} className={styles.cancelButton}><b>Annuler</b></div>
                </div>
            </div>
        :
       <div>{passwordChanged ? <p className={styles.validation}> Votre mot de passe a bien été modifié </p> : null}
            <div onClick={handleEdit} className={styles.editButton}>
                <img alt={"lock icon"} className={styles.icon} src={lock_icon}/>
                <b>Changer mon mot de passe</b>
            </div>
        </div>
    );
};

export default PasswordChanger;