import React, { useState } from "react";
import  {Disponibilite} from "./Disponibilite";
import {CalendarPonctuellePanel} from "./CalendarPonctuellePanel";
import {Tabs, Tab} from "@material-ui/core";


export const GererDisponibilite =  ()=> {

    const [selectedTab, setSelectedTab] = useState(0);
    const  handleTabs = (event, newValue) => {
        setSelectedTab(newValue);
    }

    return (
        <div className={"space-y-4"}>
                <Tabs value={selectedTab} onChange={handleTabs} >
                    <Tab label="PONCTUELLES" />
                    <Tab label="RECURRENTES" />
                </Tabs>
                { selectedTab === 0 && <CalendarPonctuellePanel/>}
                { selectedTab === 1 && <Disponibilite/>}
        </div>

    );
}

