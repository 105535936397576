import React, {useState} from 'react';
 import {AppHeader} from "../../AppHeader";
 import {isMobile} from 'react-device-detect'
 import styles from "./CalendarPanel.module.css";
 import mobileStyles from "./CalendarPanel.mobileStyles";

import Footer from "../../Footer";

import {GererDisponibilite} from "../Disponibililites/GererDisponibilite";

 export const DisponiblePanel = () => {

     const [interventionTime, interventionTimeUpdate] = useState([0,0]);
     const [interventionCount, interventionCountUpdate] = useState(0);
     const [interventionGain, interventionGainUpdate] = useState(0);
     const [idUtilisateur, setIdUtilisateur] = useState("");

     const updateWidgets = (time,count,gain) => {
         interventionTimeUpdate([(time/1)>>0,Math.round((time%1) * 60)]);
         interventionCountUpdate(count);
         interventionGainUpdate(gain);
     };

     return (
         <div>
         <div className="Content">
             <AppHeader tabValue={3} />
             <div className={isMobile ? mobileStyles.container : styles.container}>
                     <GererDisponibilite/>
             </div>
         </div>
             <Footer/>
         </div>

     );

 }
