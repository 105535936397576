import React from "react";

const Footer = () => {
    return (
        <div className="text-center w-full bg-blue-900 text-white font-semibold">
            <p>La Cinémathèque française - 51 rue de Bercy - ©2022</p>
        </div>
        )};

export default Footer;
