//Fichier de définition des couleurs pour respecter la charte graphique définie n

let colors = {
    sunray:'#EDAE49',
    brick:'#D1495B',
    seaweed:'#00798C',
    lapis:'#30638E',
    indigo:'#003D5B',
};

export default colors