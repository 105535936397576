import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from "./Session.module.css";
import {CALENDAR_URL_BB, DISPONIBILITY_URL} from "../../constants/urls";
import statusCode from "../../constants/status_codes";
import ConfirmDialog from "../../ConfirmDialog";

const Session = (props) => {

    const [isClicked, isClickedUpdate] = useState(false);
    const [ confirmDialog, setConfirmDialog ] = useState({isOpen: false, title:'',subTitle:''});

    const dataFormater =(date) =>{
        let [hh,mm,ss] = date.split(":");
        return [hh,mm].join(":")
    }


    const styleDiv = {
        backgroundColor:props.typeIntervention===statusCode.poctuelle ? '#33FDFF':'#FFA233',
        color :'black',
        textAlign: 'center',
        marginBottom :'10px',
        height:'100%',
    }

    const styleManager = (component) => {
        if (component==='container') {
            console.log("la valeur frrr",props);
            if(props.minimized)
                return (styles.containerMinimized);

            else
                return (styles.container);
        }
        else if(component === 'header'){
            if(isClicked){
                return(styles.headerClicked);
            }
            else return (styles.header);
        }
    };

        const handleCkick = () => {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            });


        let mydate =props.seanceDebut.split('/');
        let dateHeure = props.seanceDebut.substring(11);

            fetch(DISPONIBILITY_URL, {
            method:'POST',
            headers:{
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
           body:JSON.stringify({
                userId :props.idUtilisateur,
                start: mydate[1] + mydate[0] + mydate[2].slice(0,4),
                end: mydate[1] + mydate[0] + mydate[2].slice(0,4),
                period:dateHeure === '08:00:00' ? "AM":"PM",
                recurrente:props.typeIntervention===statusCode.poctuelle ? 0:1,
                actionIntervention:'D'
            })
        })
            .then((res) => {
                console.log(  "la date heure",dateHeure )
                    if (res.status === 201) {
                        props.callback && props.callback();
                    }
            })
            .catch((err) => console.log("Error adding  ", err.message));

    };

    const hmsToHours = (hms) => {
        let hours = parseInt(hms.split(':')[0],10);
        hours += parseInt(hms.split(':')[1], 10)/60;
        return hours
    };

    return(
        <div className={styleManager('container')} style={styleDiv}>

            <div className={styleManager('header')}
                 style={{height: 15 + 'em'}}
            >
                <a
                    onClick={() => {
                        setConfirmDialog({
                            isOpen: true,
                            title: 'Etes vous sùr de vouloir supprimer Cette indisponibilité ?',
                            onConfirm : () => { handleCkick()}
                        })
                    }}
                >

                <div className={styles.titleMinimized}>
                    <p className={styles.title}><b> {props.title==='Indisponibilités intervenants'?"":""}</b></p>
                    <p className={styles.place}> De {dataFormater(props.startingTime)}  à {dataFormater(props.endingTime)} </p>
                </div>

                </a>

                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                />
            </div>

        </div>

    )
};

Session.propTypes = {
    title: PropTypes.string.isRequired,
    startingTime: PropTypes.string,
    endingTime: PropTypes.string,
    description: PropTypes.string,
    rgb: PropTypes.string,
    size:PropTypes.number,
    minimized:PropTypes.bool,
    status:PropTypes.string,
    idIntervention:PropTypes.string,
    seanceDebut:PropTypes.string,
    seanceFin:PropTypes.string,
    idUtilisateur:PropTypes.number,
    typeIntervention:PropTypes.number,

};

Session.defaultProps = {
    title: '<Title>',
    startingTime: '10:00:00',
    endingTime:'12:00:00',
    job: '',
    details: '',
    money: '',
    description: '',
    size:4.3,
    minimized:false,
    rgb:'rgb(100,100,100)',
    status:'',
};

export default Session;