//const API_IP = "http://172.30.12.232:5000/";

//const API_IP = "http://localhost:5000/";
//const API_IP = "http://172.30.12.153:5000/";

const API_IP = "https://coyote.equipecf.com/api/";
/*
    export const URL = "url"
    //expectedArgument1 : valeur , expectedArgument2: valeur, etc..
*/
/*******/
/*Login*/
/*******/

//GET
export const LOGIN_URL = API_IP + "login";
//email : string

/***********/
/*Dashboard*/
/***********/

//GET
export const DASHBOARD_URL = API_IP + "dashboard";
//Date : MMddYYYY

/*************/
/*ProfilePage*/
/*************/

//GET
export const USERDETAILS_URL = API_IP + "userDetails";
//id : int

//POST
export const PASSWORDCHANGE_URL = API_IP + "changePassword";
//id : int, Password : string

/**********/
/*Calendar*/
/**********/

//GET
export const CALENDAR_URL = API_IP + "calendar";
//id : int, DateDebut : MMddYYYY, DateFin : MMddYYYY

//POST

export const DISPONIBILITY_URL = API_IP  + "disponibilities";
//IdUtilisateur : int , SeanceDebut : MMddyyyy, SeanceFin : MMddYYYY, Temps : "AM" ou "PM"


/*********/
/*Contact*/
/*********/




//GET
export const JOURNEECURRENT_URL = API_IP + "journeeCurrent";


//GET
export const JOURNEECUSER_URL = API_IP + "journeeUser";


//POST
export const DISPONIBILITY_URL_BB  = API_IP  + "Disponibilites";


//POST
export const EFFACE_URL_BB  = API_IP  + "Efface";

//IdUtilisateur : int , SeanceDebut : MMddyyyy, SeanceFin : MMddYYYY
