import React, {useState} from 'react';
import PropTypes from 'prop-types';

const Section = (props) => {

    const [isOpen, isOpenUpdate] = useState(false);

    return(
        <div className={" w-full"}>
            <div>
                <p className={"font-semibold"}>{props.title}</p>
                {props.extendable && <p
                    className={"cursor-pointer text-2xl"}
                    onClick={() => isOpenUpdate(!isOpen)}
                >
                    {isOpen ? '-' : '+'}
                </p>}
            </div>
            {((props.extendable && isOpen) || !props.extendable) && <p >{props.content}</p>}
        </div>
        )
};

Section.propTypes = {
    title: PropTypes.string.isRequired,
    extendable: PropTypes.bool,
    content: PropTypes.string.isRequired,
};

Section.defaultProps = {
    extendable: false,
};

export default Section