import React, {useState, useEffect} from 'react';
import {isMobile} from 'react-device-detect';
import useWindowSize from "../../../hooks/useWindowSize";
import styles from "./Calendar.module.css";
import CalendarDay from "../CalendarDay/CalendarDay";
import Session from "../Session/Session";
import statusCode from "../../../components/constants/status_codes";
import Cookies from "js-cookie";
import {CALENDAR_URL} from "../../constants/urls";
import {useAuth} from "../../../hooks/Auth";

/**
 * Display sessions on a certain time interval
 */

const Calendar = (props) => {

    const daysList = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    const monthsList = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    const [periodStart, periodStartUpdate] = useState([1, 1, 2020]);
    const [periodEnd, periodEndUpdate] = useState([1, 1, 2020]);
    const [range, rangeUpdate] = useState('week');
    const [filter, filterUpdate] = useState('valid');
    const [month, monthUpdate] = useState(0);
    const [year, yearUpdate] = useState(0);
    const [sessions, setSessions] = useState([[], [], [], [], [], [], []]);
    const [isLoading, isLoadingUpdate] = useState(true);
    const sessionSize = 4;
    const windowSize = useWindowSize();
    const auth = useAuth();

    /*Renvoie les informations a mettre dans les widgets*/

    const updateWidgets = (newSessions) => {
        let time = 0;
        let count = 0;
        let money = 0;
        // eslint-disable-next-line array-callback-return
        newSessions.map((day) => {
            // eslint-disable-next-line array-callback-return
            day.map((session) => {
                count++;
                time += Math.floor(session.DureeMinutes/60) + (session.DureeMinutes/60 - Math.floor(session.DureeMinutes/60))/10;
                if(session.Code_Statut !== statusCode.cancel_not_paid) money += (Math.floor(session.DureeMinutes/60) + (session.DureeMinutes/60 - Math.floor(session.DureeMinutes/60))/10)*session.RemunerationHoraire;
            });
        });

        props.updateWidgets(time,count,money);
    };

    /*Parse les sessions reçues dans sessions*/
    const parseSessions = (start, end, response, updateFilter) => {

        let newSessions = [];

        let currentDay = start;
        let daysArray = [];

        while ((currentDay[0] !== end[0]) || (currentDay[1] !== end[1])) {
            daysArray.push( [currentDay[0].toLocaleString(undefined, {minimumIntegerDigits:2}), currentDay[1].toLocaleString(undefined, {minimumIntegerDigits:2})] );
            newSessions.push([]);
            currentDay = datePlusDays(1,currentDay);
        }

        if(response.hasOwnProperty('Data')) {

            daysArray.push([currentDay[0].toLocaleString(undefined, {minimumIntegerDigits:2}), currentDay[1].toLocaleString(undefined, {minimumIntegerDigits:2})]);
            newSessions.push([]);
            let data = response.Data;

            for (let i = 0; i < data.length; i++) {
                for(let j = 0; j < daysArray.length; j++){

                    if(
                        (updateFilter === 'valid' && ((data[i].Code_Statut === statusCode.valid || data[i].Code_Statut === statusCode.contract || data[i].Code_Statut === statusCode.signed) || data[i].Code_Statut === statusCode.unavailable) )||
                        (updateFilter === 'cancel' && ( data[i].Code_Statut === statusCode.cancel_paid)) ||
                        (updateFilter === 'prevision' && (data[i].Code_Statut === statusCode.prevision || data[i].Code_Statut === statusCode.unavailable))

                    ) {
                        if (data[i].SeanceDebut !== "") {
                            if ((data[i].SeanceDebut.split(' ')[0].split('/')[0] === daysArray[j][0]) && (data[i].SeanceDebut.split(' ')[0].split('/')[1] === daysArray[j][1])) {
                                newSessions[j].push(data[i]);
                                break;
                            }
                        } else {
                            if ((data[i].InterventionDebut.split(' ')[0].split('/')[0] === daysArray[j][0]) && (data[i].InterventionDebut.split(' ')[0].split('/')[1] === daysArray[j][1])) {
                                newSessions[j].push(data[i]);
                                break;
                            }
                        }
                    }
                }
            }
            updateWidgets(newSessions);
        }

        for(let i = 0; i < newSessions.length; i++) {
            newSessions[i] = sortSessions(newSessions[i]);
        }
        setSessions(newSessions);
        //console.log(newSessions);
        isLoadingUpdate(false);
    };

    /*Tri les sessions en fonctions de l'argument donné*/
    const sortSessions = (sessionsToSort) => {
        let temp = {};
        let i = 0;
        while (i < sessionsToSort.length) {
            let j = i;
            while (j > 0 && sessionsToSort[j-1].SeanceDebut.split(' ')[1] > sessionsToSort[j].SeanceDebut.split(' ')[1])
            {
                temp = sessionsToSort[j];
                sessionsToSort[j] = sessionsToSort[j-1];
                sessionsToSort[j-1] = temp;
                j--;
            }
            i++;
        }
        return sessionsToSort;
    };

    /*Fetch les sessions en fonctions de la période*/
    const updateSessions = (start,end, updateFilter) => {
        isLoadingUpdate(true);
        fetch(CALENDAR_URL,{
            headers:{
                id: auth?.user.id ?? 2,
                startingDate: start[1].toLocaleString(undefined, {minimumIntegerDigits: 2}) + start[0].toLocaleString(undefined, {minimumIntegerDigits: 2}) + start[2],
                endingDate: end[1].toLocaleString(undefined, {minimumIntegerDigits: 2}) + end[0].toLocaleString(undefined, {minimumIntegerDigits: 2}) + end[2],
                auth: Cookies.get("auth")
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(response => parseSessions(start, end, response, updateFilter))
            .catch(error => console.log(error));

    };

    /*ComponentDidMount*/
    useEffect(() => {
        if (year === 0) {
            const today = new Date();
            let day = today.getDay();
            if (day === 0) day = 7;
            let [startingDay, startingMonth, startingYear] = dateMinusDays(0,[today.getDate() - day + 1, today.getMonth() + 1, today.getFullYear()]);
            let [endingDay, endingMonth, endingYear] = datePlusDays(0, [today.getDate() + 7 - day, today.getMonth() + 1, today.getFullYear()]);
            periodEndUpdate([endingDay, endingMonth, endingYear]);
            periodStartUpdate([startingDay, startingMonth, startingYear]);
            yearUpdate(today.getFullYear());
            updateSessions([startingDay, startingMonth, startingYear], [endingDay, endingMonth, endingYear], filter);
        }

        //eslint-disable-next-line
    }, []);

    /*Retourne la periode dans laquelle on se trouve*/
    const returnPeriod = () => {
        if (range === 'week') {
            return ('Semaine du ' + periodStart[0] + '/' + periodStart[1] + ' au ' + periodEnd[0] + '/' + periodEnd[1])
        }
        else return (monthsList[month - 1] + ' ' + year);
    };

    /*Enlève le nombre de jours en arguments à la date transmise*/
    const dateMinusDays = (days, [dateDay,dateMonth, dateYear]) => {
        dateDay -= days;
        if (dateDay < 1) {
            if (dateMonth === 3) {
                if (dateYear % 4 === 0)
                    dateDay += 29;
                else
                    dateDay += 28;

                dateMonth--;
            } else if (dateMonth === 5 || dateMonth === 7 || dateMonth === 10 || dateMonth === 12) {
                dateDay += 30;
                dateMonth--;
            } else {
                dateDay += 31;
                dateMonth--;
            }
            if (dateMonth < 1) {
                dateMonth += 12;
                dateYear--;
            }
        }

        return [dateDay,dateMonth, dateYear];
    };

    /*Fait reculer la période d'une semaine*/
    const previousWeek = () => {
        periodStartUpdate(dateMinusDays(7, periodStart));
        periodEndUpdate(dateMinusDays(7, periodEnd));
        updateSessions(dateMinusDays(7, periodStart),dateMinusDays(7, periodEnd), filter);
    };

    /*Fait reculer la période d'un mois*/
    const previousMonth = () => {
        let monthStart = periodStart;
        let monthEnd = periodEnd;
        let monthEndMonday = dateMinusDays(6, monthEnd);

        let newMonth = month - 2;
        if (newMonth < 1) newMonth += 12;

        while ((monthStart[1] !== newMonth) && ((monthStart[0] !== 1) || (monthStart[1] !== newMonth + 1))) {
            monthStart = dateMinusDays(7, monthStart);
        }

        newMonth++;
        if (newMonth > 12) newMonth -= 12;

        while (monthEndMonday[1] !== newMonth) {
            monthEndMonday = dateMinusDays(7, monthEndMonday);
            monthEnd = dateMinusDays(7, monthEnd);
        }

        periodStartUpdate(monthStart);
        periodEndUpdate(monthEnd);
        monthUpdate(newMonth);
        if(newMonth === 12) yearUpdate(year - 1);
        updateSessions(monthStart,monthEnd, filter);
    };

    /*Ajoute le nombre de jours en argument à la date transmise*/
    const datePlusDays = (days,[dateDay,dateMonth,dateYear]) => {
        dateDay += days;
        if (dateMonth === 2) {
            if ((dateYear % 4 === 0) && (dateDay > 29)) {
                dateDay -= 29;
                dateMonth++;
            } else if ((dateYear % 4 !== 0) && (dateDay > 28)) {
                dateDay -= 28;
                dateMonth++;
            }
        } else if (dateMonth === 4 || dateMonth === 6 || dateMonth === 9 || dateMonth === 11) {
            if (dateDay > 30) {
                dateDay -= 30;
                dateMonth++;
            }
        } else if (dateDay > 31) {
            dateDay -= 31;
            dateMonth++;
        }
        if (dateMonth > 12) {
            dateMonth -= 12;
            dateYear++;
        }

        return [dateDay,dateMonth, dateYear];
    };

    /*Fait avancer la période d'une semaine*/
    const nextWeek = () => {
        periodStartUpdate(datePlusDays(7, periodStart));
        periodEndUpdate(datePlusDays(7, periodEnd));
        updateSessions(datePlusDays(7, periodStart),datePlusDays(7, periodEnd), filter);
    };

    /*Fait avancer la période d'un mois*/
    const nextMonth = () => {
        let monthStart = periodStart;
        let monthStartSunday = datePlusDays(6,periodStart);
        let monthEnd= periodEnd;

        let newMonth = month + 1;
        if (newMonth > 12) newMonth -= 12;

        while (monthStartSunday[1] !== newMonth) {
            monthStartSunday = datePlusDays(7, monthStartSunday);
            monthStart = datePlusDays(7, monthStart);
        }

        newMonth++;
        if (newMonth > 12) newMonth -= 12;

        while ((monthEnd[1] !== newMonth) && !isLastDay([monthEnd[0],(month + 1)])) {
            monthEnd = datePlusDays(7, monthEnd);
        }

        newMonth--;
        if (newMonth < 1) newMonth += 12;

        periodStartUpdate(monthStart);
        periodEndUpdate(monthEnd);
        monthUpdate(newMonth);
        if(newMonth === 1) yearUpdate(year + 1);
        updateSessions(monthStart,monthEnd, filter);
    };

    /*Check si le jour qui lui est passé est le dernier du mois*/
    const isLastDay = ([dateDay, dateMonth, dateYear]) => {
        if (dateMonth === 2) {
            if ((dateYear % 4 === 0) && (dateDay === 29)) {
                return true;
            } else if ((dateYear % 4 !== 0) && (dateDay === 28)) {
                return true;
            }
        }
        else if (dateMonth === 4 || dateMonth === 6 || dateMonth === 9 || dateMonth === 11) {
            if (dateDay === 30) {
                return true;
            }
        }
        else if (dateDay === 31) {
            return true;
        }
        return false;
    };

    /*Switch affichage mois/semaine*/
    const zoom = (direction) => {
        if(direction === 'in') {
            rangeUpdate('week');
            if(periodStart[1] !== new Date().getMonth()) {
                periodEndUpdate(datePlusDays(6, periodStart));
                updateSessions(periodStart, datePlusDays(6, periodStart), filter);
            }
            else {
                const today = new Date();
                let day = today.getDay();
                if (day === 0) day = 7;
                let [startingDay, startingMonth, startingYear] = dateMinusDays(0,[today.getDate() - day + 1, today.getMonth() + 1, today.getFullYear()]);
                let [endingDay, endingMonth, endingYear] = datePlusDays(0, [today.getDate() + 7 - day, today.getMonth() + 1, today.getFullYear()]);
                periodEndUpdate([endingDay, endingMonth, endingYear]);
                periodStartUpdate([startingDay, startingMonth, startingYear]);
                yearUpdate(today.getFullYear());
                updateSessions([startingDay, startingMonth, startingYear], [endingDay, endingMonth, endingYear], filter);
            }
        }

        else if (direction === 'out') {
            let currentMonth = periodEnd[1];
            let monthStart = periodStart;
            let monthEnd = periodEnd;
            rangeUpdate('month');

            while ((monthStart[1] === currentMonth) && (monthStart[0] !== 1)) {
                monthStart = dateMinusDays(7 ,monthStart);
            }

            while(monthEnd[1] === periodEnd[1]){
                monthEnd = datePlusDays(7, monthEnd);
            }

            periodStartUpdate(monthStart);
            periodEndUpdate(monthEnd);
            monthUpdate(currentMonth);
            yearUpdate(datePlusDays(7,monthStart)[2]);
            updateSessions(monthStart,monthEnd, filter);
        }
    };

    /*Rempli les creux dans l'agenda*/
    const fillCalendar = (lastSession, newSession) => {
        if (newSession - lastSession > 0) {
            return (<div style={{height: (newSession - lastSession)*sessionSize + 'em'}}/>);
        }
    };

    //Transforme le format HH:MM:SS en H,h
    const hmsToHours = (hms) => {
        let hours = parseInt(hms.split(':')[0],10);
        hours += parseInt(hms.split(':')[1], 10)/60;
        return hours
    };

    //Gère changement de filtre
    const handleOptionSelect = (event) => {
        if(event.target.value === 'dispo') zoom('in');
        filterUpdate(event.target.value);
        updateSessions(periodStart,periodEnd, event.target.value);
    };

    //Retourne une couleur en fonction d'une string
    // noinspection DuplicatedCode
    const colorHash = (inputString) => {
        let sum = 0;

        // eslint-disable-next-line
        for(let i in inputString){
            sum += inputString.charCodeAt(i);
        }

        let r = ~~(('0.'+Math.sin(sum+1).toString().substr(6))*256);
        let g = ~~(('0.'+Math.sin(sum+2).toString().substr(6))*256);
        let b = ~~(('0.'+Math.sin(sum+3).toString().substr(6))*256);

        let rgb = "rgb("+r+", "+g+", "+b+")";

        let hex = "#";

        hex += ("00" + r.toString(16)).substr(-2,2).toUpperCase();
        hex += ("00" + g.toString(16)).substr(-2,2).toUpperCase();
        hex += ("00" + b.toString(16)).substr(-2,2).toUpperCase();

        return {
            r: r
            ,g: g
            ,b: b
            ,rgb: rgb
            ,hex: hex
        };
    };

    /*Rend le calendrier en fonction de la période*/
    const renderCalendar = () => {
        if (range === 'week') {
            let lastSession = 8;
            return (
                <div className={"flex overflow-y-auto text-center sm: grid-cols-2 md:grid-cols-2 text-black text-sm "}>
                    {sessions.map((day, i) =>
                        <div key={i} className={"flex-1 w-full"}>
                            <p className={"relative rounded-t sm: grid-cols-2 md:grid-cols-2 text-black text-sm "}>
                                {windowSize.width < 900 ? daysList[i][0] + " " + datePlusDays(i, periodStart)[0] : daysList[i] + " " + datePlusDays(i, periodStart)[0]}
                            </p>
                            <div   style={{height:sessionSize * 10+"em"}}>
                                <p    style={{display: 'none'}}>{lastSession = 8}</p>
                                {day.map((session, j) =>
                                    <div key={j}>
                                        {fillCalendar(lastSession, session.SeanceDebut !== '' ? hmsToHours(session.SeanceDebut.split(' ')[1]) : hmsToHours(session.InterventionDebut.split(' ')[1]))}
                                        <Session
                                            title={session.Evenement === "" ? session.Programme : session.Evenement}
                                            place={session.Espace}
                                            image={session.image}
                                            startingTime={session.SeanceDebut !== '' ? session.SeanceDebut.split(' ')[1] : session.InterventionDebut.split(' ')[1]}
                                            endingTime={session.SeanceFin !== '' ? session.SeanceFin.split(' ')[1] : session.InterventionFin.split(' ')[1]}
                                            job={session.Metier}
                                            details={session.details}
                                            description={session.Notes}
                                            rgb={session.Code_Statut === statusCode.unavailable ? "rgb(92,92,92)" : colorHash(session.Evenement).rgb}
                                            size={sessionSize}
                                            minimized={true}
                                            time={300}
                                            prescripteur={session.Prescripteur}

                                        />
                                        <div style={{display: 'none'}}>{lastSession = (session.SeanceFin !== '' ? hmsToHours(session.SeanceFin.split(' ')[1]) : hmsToHours(session.InterventionFin.split(' ')[1]))}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

            );
        }

        else {
            let currentDay = periodStart;
            let dayCount = 0;
            let renderMonth = [];
            let renderWeek = [];

            while ((currentDay[0] !== periodEnd[0]) || (currentDay[1] !== periodEnd[1])) {
                renderWeek.push(<CalendarDay key={dayCount} date={currentDay} className={styles.day} currentMonth={month} sessions={sessions[dayCount]}/>);
                currentDay = datePlusDays(1,currentDay);
                dayCount++;
                if(dayCount % 7 === 0) {
                    renderMonth.push(<div key={"week " + dayCount/7} className={styles.week}>{renderWeek}</div>);
                    renderWeek=[];
                }
            }
            renderWeek.push(<CalendarDay key={"week " + dayCount/7} date={currentDay} className={styles.day}/>);
            renderMonth.push(<div key={"month"} className={styles.week}>{renderWeek}</div>);

            return (
                <div>
                    <div className={"w-full flex text-center"}>
                        {daysList.map((day, i) =>
                            <div key={i} className={"w-full"}>
                                <p className={"text-white font-semibold bg-blue-900 bg-opacity-90 w-full"}>{isMobile ? day[0] : day}</p>
                            </div>
                        )}
                    </div>
                    {renderMonth}
                </div>
            );
        }
    };


    const loadingSessions = () => {
        return(
            <div className={styles.loading}>
                Chargement de l'emploi du temps...
            </div>
        )
    };

    return(
        <div>
            <div className={styles.container}>
                <div className={styles.header}>

                    <div className={styles.filterContainer}>
                        <select onChange={handleOptionSelect} className={styles.filterSelection}>
                            <option value={'valid'}>Validées</option>
                            <option value={'prevision'}>Prévisionnelles</option>
                            <option value={'cancel'}>Annulées à payer</option>
                        </select>
                    </div>

                    <div className={styles.time}>
                        <div className={isLoading ? styles.leftArrowDisabled : styles.leftArrow} onClick={isLoading ? null : (range === 'week' ? () => previousWeek() : previousMonth)}/>
                        <div className={styles.title}><b>{returnPeriod()}</b></div>
                        <div className={isLoading ? styles.rightArrowDisabled : styles.rightArrow} onClick={isLoading ? null : (range === 'week' ? () => nextWeek() : nextMonth)}/>
                    </div>

                    <div className={styles.zoomContainer} style={filter === 'dispo' ? {display: 'none'} : null}>
                        <div onClick={range === 'month' ? null : () => zoom('out')}
                             className={range === 'month' ? styles.zoomEnabled :styles.zoomDisabled}
                        >
                            <b>Mois</b>
                        </div>

                        <div onClick={range === 'month' ? () => zoom('in') : null}
                             className={range === 'month' ? styles.zoomDisabled : styles.zoomEnabled}
                        >
                            <b>Semaine</b>
                        </div>
                    </div>
                </div>
                {isLoading ? loadingSessions() : renderCalendar()}
            </div>

        </div>

    )
};

export default Calendar;