export const constructHeader = (contentType) => {
  const auth = "Bearer " + localStorage.getItem("token") || "";
  return contentType
    ? { "Content-type": contentType, Authorization: auth }
    : { Authorization: auth };
};


export const API_LISTE_INTERVENANTS= "/api/utilisateur/intervenants";





