import React, {useState, useEffect} from 'react';
import styles from "./ProfilePage.module.css";
import {USERDETAILS_URL} from "../../../constants/urls";
import Cookies from "js-cookie";
import {AppHeader} from "../../AppHeader";
import {Grid, Typography,CardHeader} from "@material-ui/core";
import Footer from "../../Footer";
import PasswordChanger from "../PasswordChanger/PasswordChanger";
import userDefaultPicture from "../../../resources/default-user-image.png";
import {useAuth} from "../../../hooks/Auth";


const ProfilePage = (props) => {

    const auth = useAuth();
    const [userData, userDataUpdate] = useState({});


    useEffect(() => {
        fetch(USERDETAILS_URL, {
            headers:{
                id : auth.user.id,
                auth: Cookies.get("auth")
            },
            method: "GET",
        })
            .then(response => response.json())
            .then(response => userDataUpdate(response))
            .catch(err => err);
        // eslint-disable-next-line
    }, []);

    return(
        <div className={"w-full h-full space-y-4 overflow-auto"}>

                     <AppHeader />
                        <div className={styles.infos}>
                            <div className={styles.image} style={userData.hasOwnProperty('image')? {backgroundImage:'url('+userData.image+')'} : {backgroundImage:'url('+userDefaultPicture+')'}}/>
                            <p className={styles.name}>{userData.PrenomCivil} <b>{userData.NomCivil}</b></p>
                            <PasswordChanger userId={auth.user.id}/>
                        </div>



                    <div id="contener"  className="container bg-gray-100 mx-auto">

                    <Grid item style={{ marginBottom: "5vh" }}>
                        <Typography>
                                   <span role="img" aria-label="journeeUser"></span>
                        </Typography>

                        <Grid container direction="column" alignItems="center" className="User">
                            <Grid item xs={12}>
                                <Grid container style={{ marginBottom: "0.7em" }} color="primary"
                                >
                                    <Typography variant="body2" gutterBottom >
                                     <div     className="container bg-blue-800 mx-auto text-fuchsia-50">
                                        <CardHeader title="Informations Générales"/>
                                    </div>
                                    <div  className={styles.section}>
                                            <Grid>
                                                <b>Mutuelle : </b>{userData.Mutuelle !== "" ? userData.Mutuelle :'N/A'}
                                            </Grid>
                                            <Grid>
                                                <b>Civilité : </b>{userData.Genre !== "" ? userData.Genre :'N/A'}
                                            </Grid>
                                    </div>
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Grid>

                            <Grid item xs={12}>
                                <Grid container alignItems="center" style={{ marginBottom: "0.7em" }} color="primary">
                                    <Typography variant="body2" gutterBottom>
                                        <div     className="container bg-blue-800 mx-auto text-fuchsia-50">
                                            <CardHeader title="Informations Personnelles"/>
                                        </div>
                                        <div  className={styles.section}>
                                        <Grid>
                                            <b>Date de naissance : </b>{userData.DateNaissance !== "" && userData.DateNaissance !== undefined ? userData.DateNaissance.split(' ')[0] :'N/A'}
                                        </Grid>
                                        <Grid>
                                            <b>Département de naissance : </b>{userData.DepartementNaissance !== "" ? userData.DepartementNaissance :'N/A'}
                                        </Grid>
                                        <Grid >
                                            <b>Commune de naissance : </b>{userData.CommuneNaissance !== "" ? userData.CommuneNaissance :'N/A'}
                                        </Grid>
                                        <Grid>
                                            <b> numéro de congé spectacle : </b>{userData.NumeroCongesSpectacles !== "" ? userData.NumeroCongesSpectacle :'N/A'}
                                        </Grid>

                                        <Grid>
                                        <b>Numéro Agessa : </b>{userData.NumeroAgessa !== "" ? userData.NumeroAgessa :'N/A'}
                                        </Grid>
                                        </div>
                                        <div     className="container bg-blue-800 mx-auto text-fuchsia-50">
                                        <CardHeader title="Adresse"/>
                                        </div>

                                        <div  className={styles.section}>
                                        <Grid>
                                            <b>Adresse: </b>  {userData.AdresseRue !== "" ? userData.AdresseRue :'N/A'}
                                        </Grid>

                                        <Grid>
                                                <b>Complément d'adresse :
                                                </b>{userData.AdresseComplement !== "" ? userData.AdresseComplement :'N/A'}
                                        </Grid>

                                        <Grid>
                                                <b>Code Postal : </b>{userData.AdresseCodePostal !== "" ? userData.AdresseCodePostal :'N/A'}
                                        </Grid>
                                            <Grid>
                                                <b>Ville : </b>{userData.AdresseVille !== "" ? userData.AdresseVille :'N/A'}
                                            </Grid>

                                            <Grid>
                                                <b>Pays de résidence : </b>{userData.AdressePays !== "" ? userData.AdressePays :'N/A'}
                                            </Grid>

                                            <Grid>
                                                <b>Contact : </b>{userData.Contact !== "" ? userData.Contact :'N/A'}
                                            </Grid>
                                        </div>
                                    </Typography>

                                </Grid>

                        </Grid>



                    </Grid>


        </div>
            <Footer/>
        </div>

    );
};

export default ProfilePage;