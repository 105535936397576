//const API_IP = "http://172.30.12.232:5000/";

//const API_IP = "http://localhost:5000/";
//const API_IP = "http://172.30.12.153:5000/";
const API_IP = "https://coyote.equipecf.com/api/";

/*Login*/
/*******/

//GET
export const LOGIN_URL = API_IP + "login";
//email : string

/***********/
/*Dashboard*/
/***********/


//GET CALANDAR
export const CALENDAR_URL = API_IP + "calendar";


//GET
export const CALENDAR_URL_BB = API_IP + "calendar";



//POST
export const DISPONIBILITY_URL = API_IP  + "disponibilities";
//IdUtilisateur : int , SeanceDebut : MMddyyyy, SeanceFin : MMddYYYY, Temps : "AM" ou "PM"


//GET
export const INDISPONIBILITY_URL = API_IP + "indisponibilitesUser";


