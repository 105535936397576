import { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuth } from './Auth';

const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect( () => {
    const userId = Cookies.get('userId');
    if (!!userId && auth) {
      auth.setUser({...auth.user, id: userId});
    }
    setLoading(false);
    //eslint-disable-next-line
  }, []);

  return (
    <Route render={
      ({ location }) => {
        if (!loading) {
          return !!auth.user.id ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />;
        }
        return (
          <div className="w-screen h-screen">
            <p>Loading...</p>
          </div>
        );
      }
    }
    />
  );
};

PrivateRoute.propTypes = {
};

export default PrivateRoute;
