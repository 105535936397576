const statusCode = {
    prevision : 431,
    valid : 432,
    contract : 433,
    signed : 434,
    cancel_paid : 435,
    cancel_not_paid : 436,
    cancel_absent : 437,
    unavailable : 82,
    indisponible : 504,
    disponible : 505,
    poctuelle : 5,
    recurrente : 6,
};

export default statusCode;