import React from 'react';
import "./styles.css";
import {Redirect, Route, Switch} from "react-router-dom";
import './constants/colors.css';
import {CalendarPanel} from "./components/Panels/Calendar/CalendarPanel";
import {JourneeCurrent} from "./components/Panels/Dashboard/JourneeCurrent";
import {JourneeUser} from "./components/Panels/Dashboard/JourneeUser";
import LoginPanel from "./components/Panels/Login/LoginPanel";
import ProfilePage from "./components/Panels/ProfilePage/ProfilePage";
import PasswordChanger from "./components/Panels/PasswordChanger/PasswordChanger";
import PageNotFound from "./components/UI/PageNotFound";
import {DisponiblePanel} from "./components/Panels/Calendar/DisponiblePanel";
import {AuthProvider} from "./hooks/Auth";
import PrivateRoute from "./hooks/PrivateRoute";



function App() {

    return (
        <AuthProvider>
                <div className="h-screen w-screen">
                    <Switch>
                        <PrivateRoute exact path="/calendrier">
                            <CalendarPanel/>
                        </PrivateRoute>
                        <PrivateRoute exact path="/journeeUser">
                            <JourneeUser/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/journeeCurrent">
                            <JourneeCurrent/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/indisponibilitesUser">
                            <DisponiblePanel/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/profile">
                            <ProfilePage/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/changePassword">
                            <PasswordChanger/>
                        </PrivateRoute>

                        <Route exact path="/login">
                            <LoginPanel/>
                        </Route>

                        <Redirect to={'/login'}/>
                        <Route component={PageNotFound}/>
                    </Switch>
                </div>
        </AuthProvider>

    );
}

export default App;
