import React from 'react';
import CalendarPonctuelle from "../../../components/Agenda/Calendar/CalendarPonctuelle";
 export const CalendarPonctuellePanel = () => {

     return (

         <div className={"h-full w-full space-y-4 px-16"}>
             <div className={"border border-gray-300 rounded uppercase w-fit p-2"}>
                 <div className={"flex items-center space-x-4" }>
                     <div className={"h-4 w-4 flex-none rounded"} style={{background:"#33FDFF"}}/>
                     <p>INDISPONIBILITES PONCTUELLES</p>
                 </div>
                 <div className={"flex items-center space-x-4" }>
                     <div className={"h-4 w-4 flex-none rounded"} style={{background:"#FFA233"}}/>
                     <p>INDISPONIBILITES RECURRENTES</p>
                 </div>
             </div>
             <CalendarPonctuelle updateWidgets={() => {}}/>
         </div>



     );

 }
