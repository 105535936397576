import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from "./Session.module.css";
import Section from "./Section/Section";

/**
 * Session displayed in Planning & Calendar
 */

const Session = (props) => {

    const [isExtended, isExtendedUpdate] = useState(false);

    const dataFormater =(date) =>{
        let [hh,mm,ss] = date.split(":");
        return [hh,mm].join(":")
    }
    //Transforme le fort HH:MM:SS en H,h
    const hmsToHours = (hms) => {
        let hours = parseInt(hms.split(':')[0],10);
        hours += parseInt(hms.split(':')[1], 10)/60;
        return hours
    };

    return(
        <div className={"relative rounded-t sm: grid-cols-2 md:grid-cols-2 text-black text-sm  " } style={{backgroundColor: props.rgb} }  >
            <div className={"sm: grid-cols-2 md:grid-cols-2 text-black text-sm"}
                 style={{height: (hmsToHours(props.endingTime) - hmsToHours(props.startingTime)) * props.size + 'em'}}
                 onClick={() => {isExtendedUpdate(!isExtended)}}
            >
                <div className={"sm: grid-cols-2 md:grid-cols-2 text-black text-sm"}>
                    <p className={styles.title}><b>{props.title}</b></p>
                    <p className={styles.title}>{props.place}</p>
                    <p className={styles.title}>{props.startingTime.slice(0,5) + ' - ' + props.endingTime.slice(0,5)}</p>
                </div>

            </div>
            <div className={styles.title}
                 style={{backgroundColor: props.rgb}}
            >
                {isExtended && <div>
                {props.prescripteur !== '' ? <Section content={props.prescripteur} title={"Prescripteur : "}/> : null}
                {props.details !== '' ? <Section content={props.details} title={"Détails : "}/> : null}
                {props.money !== '' ? <Section content={props.money + ' €'} title={"Taux horaire brut : "}/> : null}
                {props.description !== '' ? <Section content={props.description} title={"Détail de l'intervention : "} extendable={true}/> : null}
                </div>}
            </div>
        </div>
    )
};

Session.propTypes = {
    title: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    startingTime: PropTypes.string,
    endingTime: PropTypes.string,
    job: PropTypes.string,
    details : PropTypes.string,
    money : PropTypes.string,
    description: PropTypes.string,
    rgb: PropTypes.string,
    size:PropTypes.number,
    minimized:PropTypes.bool,
    prescripteur:PropTypes.string,
};


Session.defaultProps = {
    title: '<Title>',
    place: '<Place>',
    image:" ",
    startingTime: '10:00:00',
    endingTime:'12:00:00',
    job: '',
    details: '',
    money: '',
    description: '',
    size:4.3,
    minimized:false,
    rgb:'rgb(100,100,100)',
    prescripteur:'',
};

export default Session;