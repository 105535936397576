import React, {useState} from 'react';
import styles from './DisponibilitySelector.module.css';
import PropTypes from 'prop-types';

/**
 *  Allow to select a ponctual indisponibility
 */

const DisponibilitySelector = (props) => {
    const [clicked, clickedUpdate] = useState(props.alreadyChecked);
    const [count, setcount] = useState(0);
    const handleOnClick = () => {
        console.log("+++++++++++++++++++++");
        setcount(count+ 1);
        console.log("Vous me cliquer SPT " +count);
     if(count < 1){
     if(clicked || props.alreadyChecked) {
         clickedUpdate(!clicked);
         props.updateDispo(props.date, props.period);
     }
     else if(props.editable) {
         clickedUpdate(!clicked);
         props.updateDispo(props.date, props.period);
     }
 }
    };
   return(
           <div
           className={props.editable || props.alreadyChecked  ? (clicked ? styles.containerClicked : styles.container) : styles.containerDisabled}
           onClick={handleOnClick} />
    )
};

DisponibilitySelector.propTypes = {
    period : PropTypes.oneOf(['AM','PM']).isRequired,
    date : PropTypes.arrayOf(PropTypes.string).isRequired,
    editable:PropTypes.bool,
    addInter:PropTypes.bool,
};

export default DisponibilitySelector;