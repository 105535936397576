import React, {useState} from 'react';
 import {AppHeader} from "../../AppHeader";
 import {isMobile} from 'react-device-detect'
 import Calendar from "../../Agenda/Calendar/Calendar";
 import Widget from "../../UI/Widgets/widget";
 import colors from "../../../constants/colors";
 import okIcon from '../../../resources/icons/ok.png';
import Footer from "../../Footer";

 export const CalendarPanel = (props) => {

     const [interventionTime, interventionTimeUpdate] = useState([0,0]);
     const [interventionCount, interventionCountUpdate] = useState(0);
     const [interventionGain, interventionGainUpdate] = useState(0);

     const updateWidgets = (time,count,gain) => {
         interventionTimeUpdate([(time/1)>>0,Math.round((time%1) * 60)]);
         interventionCountUpdate(count);
         interventionGainUpdate(gain);
     };

     return (
         <div className={"w-full h-full space-y-4"}>
             <AppHeader tabValue={0} />
             <div className="flex-grow">
             <div className={"space-y-4"}>
                 <div>
                     <Calendar updateWidgets={updateWidgets}/>
                 </div>
                 <div className={"w-full flex justify-center"}>
                     <Widget
                         icon={okIcon}
                         color={colors.lapis}
                         reversed={true}
                         title={'Interventions prévues sur cette période : '}
                         value={interventionCount}
                     />

                 </div>
             </div>
         </div>
             <Footer/>
         </div>

     );

 }
