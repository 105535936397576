import React, {useState} from "react";
import {AppHeader} from "../../AppHeader";
import {Grid, Paper, Typography} from "@material-ui/core";
import styles from "./Dashboard.module.css";
import Footer from "../../Footer";
import PlanningUser from "../../Agenda/Planning/PlanningUser";
import {useAuth} from "../../../hooks/Auth";

export   const JourneeUser = (props) => {

    const auth = useAuth()

    const [interventions, interventionsUpdate] = useState(0);
    const [interventionTime, interventionTimeUpdate] = useState([0,0]);

    const updateWidgets = (newInterventions, newInterventionTime, newMoney) => {
        interventionsUpdate(newInterventions);
        interventionTimeUpdate([(newInterventionTime/1)>>0,Math.round((newInterventionTime%1) * 60)]);

    };
    return (
        <div>
        <div className="Content">
            <AppHeader tabValue={1} />
            <Grid container alignItems="center" direction="column">
                <Grid item style={{ marginBottom: "5vh" }}>
                    <Typography variant="h4"   color="textSecondary" component="h2" gutterBottom>
                     <Paper> Ma journée à la Cinémathèque française du {new Date().toLocaleDateString()}</Paper>
                        <span role="img" aria-label="journeeUser"></span>
                    </Typography>

                    <div className={styles.dashboardContainer}>
                        <PlanningUser userId={auth.user.id} widgetsCallback={updateWidgets}/>
                    </div>
                </Grid>
            </Grid>


        </div>
            <Footer/>
        </div>

    );

}
